.big--container {
	background: #e8f2fe;
	height: 100vh;
	overflow: hidden;
	z-index: 1;
}

.container--component--page {
	padding-left: 2rem;
	top: 60px;
	left: 80px;
	width: 100%;
	height: 85%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.page--title {
	/* position: absolute; */
	font-family: "Betm Rounded Medium";
}

.component {
	width: 85%;
	z-index: 2;
	height: 100px;
	display: flex;
	flex-flow: wrap row;
	justify-content: flex-start;
	align-items: center;
}
