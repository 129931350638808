.container--pagination {
	position:fixed;
	bottom: 0;
	right:0;
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.container--pagination span {
	font-family: "Betm Rounded Regular";
	font-size: 15px;
	margin: 0 10px;
}

.container--pagination select,
.container--pagination option {
	font-family: "Betm Rounded Regular";
	font-size: 15px;
	background-color: transparent;
	border: 0;
}

.container--pagination button {
	width: 20px;
	font-family: "Betm Rounded Regular";
	font-size: 15px;
	background-color: transparent;
	border: 0;
	border-radius: 50px;
}

.container--pagination button:hover {
	background-color: rgba(128, 128, 128, 0.178);
}
