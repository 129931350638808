.container--popup--import {
        position: fixed;
        z-index: 60;
}

.background--popup--import {
	/* -webkit-backdrop-filter: blur(10px); */
	position: absolute;
	width: 100vw;
	height: 100vh;
	/* filter: blur(5px); */
	background-color: rgba(255, 255, 255, 0.623);
	/* ============ */
}

.import__form {
    position: fixed;
    top: 20%;
    width: 60%;
    height: 70%;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
}

.change__container {
    width: 50%;
}

.change__content {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}