.container--table {
	width: calc(100% - 80px - 2rem);
	height: 65vh;
}

/* ================================================= */
/* LOGO ADD */

.container--button--add {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.add--logo {
	width: 30px;
	cursor: pointer;
	opacity: 0.5;
	margin-bottom: 0.5rem;
	margin-left: 10px;
}

.add--logo:hover {
	opacity: 1;
}

/* ================================================= */
/* COMPONENT TABLE */

.table--page {
	width: 100%;
	height: 100%;
}

/* ================================================= */
/* SEARCH INPUT */

/* .data-table-extensions-filter {
	background-color: #fff;
	border-radius: 15px;
	outline: none;
} */

/* .data-table-extensions-filter input {
	margin-left: 20px;
}

.data-table-extensions-filter label {
	width: 50%;
} */

/* ================= Table ======================= */

/* .rdt_TableRow {
	overflow: hidden;
} */

/* ===================================================== */

/* .delete--logo {
	width: 80%;
	cursor: pointer;
	opacity: 0.5;
}

.delete--logo:hover {
	opacity: 1;
}

.update--logo {
	width: 100%;
	cursor: pointer;
	opacity: 0.5;
}

.update--logo:hover {
	opacity: 1;
}

.expanded--row {
	height: 60px;
	padding: 10px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	overflow-y: auto;
}

.expanded--row p {
	margin-left: 450px;
	width: 300px;
} */

/* ==================================== */
/* SCROLLBAR */

/* Works on Firefox */
* {
	/* position: fixed;
	scrollbar-width: 10px;
	scrollbar-color: #7079b9 #e8f2fe; */
}
