.container--menuAction--dep {
	bottom: 0;
	padding: 15px;
	position: fixed;
	z-index: 4;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	background-color: transparent;
}

.white--font--menu {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: white;
	opacity: 0.8;
	padding: 20px;
	border-radius: 15px;
	box-shadow: 0 0 2em lightgray;
}

/* .container--menuAction--min {
	width: 5%;
	height: 100vh;
	padding: 15px;
	position: fixed;
	z-index: 4;
	background-color: #afd3ff;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
} */

.action--button {
	width: 100%;
	height: 50px;
	margin: 10px 0px;
	padding: 10px;
	cursor: pointer;
	border-radius: 5px;
	text-decoration: none;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.action--button img {
	width: 35px;
	transition: all 0.2s ease-in-out;
}

.action--button span {
	font-size: 20px;
	font-family: Betm Rounded Regular;
	transition: font-size 0.2s;
}

.action--button:hover {
	text-decoration: none;
}

.action--button:hover img {
	text-decoration: none;
	margin-left: 10px;
	transform: scale(1.5);
}

.action--button:hover span {
	font-size: 30px;
	margin-left: 15px;
}

.select img {
	margin-left: 10px;
	transform: scale(1.5);
}

.select span {
	font-size: 30px;
	margin-left: 15px;
}

.button--hide {
	display: none;
}

.button--dep {
	text-decoration: none;
	margin-left: 15px;
}

.settingsbutton {
	text-decoration: none;
	flex-direction:column;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 15px;
	z-index: 5;
}

.settingsbutton span {
	font-size: 20px !important;
}
