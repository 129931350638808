.import__fields {
    position: fixed;
    top: 20%;
    width: 60%;
    height: 60vh;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
}

.import__fields__select {
    display:flex;
    flex-direction: row;
}

.import__fields__list {
    overflow-y: scroll;
}


.import__fields__select select { 
    border: 0;
	font-family: "Betm Rounded Medium";
    padding-left: 10px;
    font-size: 25px;
    outline: none;
}