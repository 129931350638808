* {
	margin: 0px;
	padding: 0px;
	position: relative;
	box-sizing: border-box;
	font-family: "Arial";
}

@font-face {
	font-family: "Betm Rounded Black";
	src: url("./assets/fonts/Typesketchbook\ -\ Betm\ Rounded\ Black.ttf");
}

@font-face {
	font-family: "Betm Rounded Light";
	src: url("./assets/fonts/Typesketchbook\ -\ Betm\ Rounded\ Light.ttf");
}

@font-face {
	font-family: "Betm Rounded Thin";
	src: url("./assets/fonts/Typesketchbook\ -\ Betm\ Rounded\ Thin.ttf");
}

@font-face {
	font-family: "Betm Rounded Regular";
	src: url("./assets/fonts/Typesketchbook\ -\ Betm\ Rounded\ Regular.ttf");
}
@font-face {
	font-family: "Betm Rounded Medium";
	src: url("./assets/fonts/Typesketchbook\ -\ Betm\ Rounded\ Medium.ttf");
}
