.graph__settings__holder {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    height: 100vh;
    width: 30%;
    padding-left: 20px;
    background-color: white;
    z-index: 3;
    box-shadow: rgb(52 58 67 / 8%) 0px 20px 40px, rgb(52 58 67 / 8%) 0px 10px 20px, rgb(52 58 67 / 10%) 0px 0px 2px;
}

.graph__settings__extend {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: white;
    stroke: rgb(51, 51, 51);
    color: rgb(51, 51, 51);
    fill: rgb(51, 51, 51);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position:fixed;
    top: 0;
    width: 30px;
    height: 60px;
    z-index: 99;
    box-shadow: rgb(52 58 67 / 8%) 0px 20px 40px, rgb(52 58 67 / 8%) 0px 10px 20px, rgb(52 58 67 / 10%) 0px 0px 2px;
}

.graph__settings__extend :hover{
    cursor: pointer;
}

.graph__settings__menu {
    width: 100%;
    height: 50px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
}

.graph__settings__menu :hover{
    cursor: pointer;
}

.graph__settings__menu__select {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.graph__settings__holder span{
    font-size: 15px;
    font-family: Betm Rounded Regular;
}

.graph__settings__content {
    height: 100%;
    width: 100%;
    padding: 5px;
    overflow-y: scroll;
}

.graph__settings__options {
    height: 20%;
    width: 100%;
    padding: 5px;
}

.graph__settings__content__cartridge {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
}

.graph__settings__content__cartridge span{
    padding: 0px 8px;
}

.graph__settings__list {
    margin-top: 1em;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.graph__settings__list::-webkit-scrollbar {
    display: none;
  }

.graph__settings__content__cartridge__action {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: 0px solid black;
    margin: 10px;
}


.graph__settings__content__cartridge__action :hover{
    cursor: pointer;
}

.graph__settings__content__cartridge__info {
    box-shadow: rgb(52 58 67 / 8%) 0px 1px 4px, rgb(52 58 67 / 8%) 0px 1px 2px, rgb(52 58 67 / 10%) 0px 0px 2px;
    background-color: rgb(255, 255, 255);
    height: 40px;
    border-radius: 20px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 180px;
}

.separator {
    width: 1px;
    height: 100%;
    background-color: rgb(188, 188, 188);
}

.graph__settings__content::-webkit-scrollbar {
    display: none;
}