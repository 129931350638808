.container--relation--page {
	padding-left: 2rem;
	top: 60px;
	left: 80px;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.page--title {
	font-family: "Betm Rounded Medium";
}

.relation {
	width: 85%;
	z-index: 2;
	display: flex;
	flex-flow: wrap row;
	justify-content: flex-start;
	align-items: center;
}
