.container--logo {
	width: 100px;
	position: fixed;
	top: -10px;
	left: 10px;
	z-index: 99;
}

.logo {
	width: 100%;
}
