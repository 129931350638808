.container--popupSettings {
	width: 60%;
	height: 80%;
	position: fixed;
	top: 10%;
	left: 20%;
	z-index: 60;
}

.container--carto {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 25px;
    font-size: 25px;
    border-radius: 20px;
    font-size: 18px;
    text-align: center;
    background-color: #fff;
    box-shadow: 8px 8px 30px #aaa;
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.background--popupSettings {
	/* -webkit-backdrop-filter: blur(10px); */
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0px;
	left: 0px;
	/* filter: blur(5px); */
	background-color: rgba(255, 255, 255, 0.623);
	/* ============ */
}

.settings {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.setting {
    width: 50px;
    margin: 5px;
    padding: 5px;
}

.setting--selected {
    width: 50px;
    margin: 5px;
    padding: 5px;
    border: 3px solid black;
}

.graph__save__graph {
    height: 100px;
    width: 100px;
    cursor: pointer;
	overflow: hidden;
    border: solid 3px black;
}

.content {
	width: auto;
}

.carto--list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
	grid-auto-rows: minmax(100px, auto);
}

.carto--list span {
    font-family: "Betm Rounded Medium";
    color: #212529;
}