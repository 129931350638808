.container--map--page {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.container--inputbox {
	position: absolute;
	left: 150px;
	height: 70px;
	width: 40%;
	display: flex;
	flex-direction: row;
	z-index: 10;
}

.inputbox {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	box-shadow: rgb(52 58 67 / 8%) 0px 20px 40px, rgb(52 58 67 / 8%) 0px 10px 20px, rgb(52 58 67 / 10%) 0px 0px 2px;
	background-color: white;
}

.entries {
	display: flex;
	flex-direction: row;
}

.inputbox input {
	height: 50px;
	width: 90%;
	border: none;
	/* background: transparent; */
}

.buttonEnter {
	width: 50px;
	height: 50%;
	margin-left: 10px;
	padding: 3px;
	border: solid 1px black;
	border-radius: 5px;
	cursor: pointer;
}

.suggestionModal {
	width: 100%;
	margin-top: 70px;
	left: 0px;
	position: absolute;
	z-index: 1;
	display: flex;
	flex-direction: row;
}

.input--list {
	width: 50%;
	background-color: antiquewhite;
	display: flex;
	flex-direction: column;
	border: 0.5px solid black;
}
.input--list--empty {
	width: 50%;
	display: flex;
	flex-direction: column;
}

.cartridge--list--name {
	height: 30px;
	padding: 5px 10px;
	margin: 5px;
	font-size: 12px;
	border-radius: 10px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	opacity: 0.9;
}

.cartridge--list--name:hover {
	opacity: 1;
}

.name--selected {
	opacity: 0;
}

.GraphContainer {
	width: 100%;
	height: 100%;
}
