.container--form--login {
	width: 100%;
	height: 100vh;
	position: absolute;
	z-index: 10;
	font-size: 18px;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.699);
}

.form--connexion {
	width: 40%;
	height: 40%;
	position: fixed;
	top: 15%;
	left: 30%;
	background-color: #fff;
	border-radius: 20px;
	border: 2px solid black;
	box-shadow: 8px 8px 30px #aaa;
	z-index: 11;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}
