.container--popup {
	width: 50%;
	height: 70%;
	position: fixed;
	top: 15%;
	left: 30%;
	z-index: 60;
}

.container--popup--error {
	width: 30%;
	height: 5%;
	position: fixed;
	top: 5%;
	left: 50%;
    transform: translate(-50%,-5%);
	z-index: 60;
	border-radius: 20px;
	background-color: rgba(255, 255, 255, 0.8);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	font-family: "Betm Rounded Regular";
	text-align: center;
	color: red;
}

.container--popup--listRelation {
	width: 40%;
	height: 80%;
	position: fixed;
	top: 15%;
	left: 30%;
	z-index: 6;
}

.background--popup {
	/* -webkit-backdrop-filter: blur(10px); */
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: -150px;
	left: -570px;
	/* filter: blur(5px); */
	background-color: rgba(255, 255, 255, 0.623);
	/* ============ */
}

.foreground--popup {
	width: 100%;
	height: 100%;
	padding: 50px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	border-radius: 20px;
	background-color: #fff;
	box-shadow: 8px 8px 30px #aaa;
}

.foreground--popup h1 {
	font-family: "Betm Rounded Medium";
}

.foreground--popup p,
.foreground--popup b {
	width: 80%;
	font-size: 25px;
	font-family: "Betm Rounded Regular";
	text-align: center;
}

/* ============================= */

.popComponent h1,
.popComponent p {
	color: #7079b9;
}

.popComponent p {
	width: 84%;
}

.popRelation h1,
.popRelation p {
	color: #8758a0;
}

/* ============================= */

.container--cartridge {
	width: 70%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.cartridgeName {
	/* max-height: 70px; */
	padding: 0px 10px;
	margin: 5px;
	font-size: 20px;
	font-family: "Betm Rounded Regular";
	border-radius: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: #fff;
}

/* =========================================== */

.container--button--popup {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

#number {
	color: #ff0000;
}

/* ============================================ */

.container--renderList {
	width: 100%;
	/* margin: 20px; */
	/* max-height: 200px; */
	height: 200px;
	overflow-y: scroll;
}

.container--listRelation {
	/* min-height: 20px; */
	/* height: 20px; */
	max-height: 60px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.listRelation {
	width: 100%;
	/* height: 50px; */
	font-size: 20px;
	/* margin-top: 15px; */
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.listRelation p {
	width: 50%;
	font-size: 20px;
}

.lign--separator {
	width: 100%;
	margin-bottom: 5px;
	border: 1px solid #7079b9;
	/* display: none; */
}
