.graph__filter__holder {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: calc(70px + 3rem);
    height: 100vh;
    width: 400px;
    z-index: 13;
    box-shadow: rgb(52 58 67 / 8%) 0px 20px 40px, rgb(52 58 67 / 8%) 0px 10px 20px, rgb(52 58 67 / 10%) 0px 0px 2px;
    background-color: white;
}

.graph__filter__menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 10px 0px 10px;
    margin-top: 1rem;
}


.graph__filter__menu span{
    margin-right: 1em;
}

.graph__filter__holder span{
    font-size: 15px;
    font-family: Betm Rounded Regular;
}

.graph__filter__add {
    color: rgb(104, 189, 244);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.graph__filter__close { 
    cursor: pointer;
    margin: 0px 0px 0px auto;
}

.graph__filter__content {
    height: 100%;
    width: 100%;
    padding: 5px;
}

.graph__filter__list {
    margin-left: 26px;
    margin-top: -10px;
    padding-top: 10px;
    overflow-y: scroll;
    justify-content: space-between;
}

.graph__filter__list::-webkit-scrollbar {
    display: none;
  }