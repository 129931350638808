.graph__filter__cartridge {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin-bottom: 1em;
    font-size: 1.2em;
    background-color: rgb(255, 255, 255);
}

.graph__filter__cartridge .head {
    margin-top: 1rem;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
	font-size: 15px;
}

.graph__filter__cartridge .headcartridge {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.graph__filter__cartridge select {
    border-color: rgba(34,36,38,.15);
}

.graph__filter__cartridge input {
    border-color: rgba(34,36,38,.15);
}

.graph__filter__cartridge .head :hover{
    cursor: pointer;
 }
 
.graph__filter__cartridge .icon :hover{
    cursor: pointer;
 }

 .graph__filter__values {
     display: flex;
     flex-direction: column;
	 font-size: 10px; 
     max-height: 200px;
     overflow-y: scroll;
 }
 
 .graph__filter__select {
     display: flex;
     flex-direction: column;
     padding-left: 5px;
 }
 
hr {
	margin: 5px 0 5px 0;
}


.customScrollbar::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 3px solid rgba(0,0,0,0);
    border-radius: 6px;
    min-height: 36px;
}

.customScrollbar::-webkit-scrollbar-thumb {
    background-color: hsla(0,0%,0%,0.35);
}

.customScrollbar::-webkit-scrollbar-button {
    display: none;
    height: 0;
    width: 12px;
}

.customScrollbar::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}