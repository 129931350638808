.container--form {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	padding: 25px;
	font-size: 25px;
	border-radius: 20px;
	font-size: 18px;
	text-align: center;
	background-color: #fff;
	box-shadow: 8px 8px 30px #aaa;
	display: block;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.container--form h2 {
	font-family: "Betm Rounded Medium";
}

.container--cartridge {
	width: 70%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.container--tuple {
	width: 80%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.container--tuple--update {
	width: 80%;
	height: 50%;
	overflow-y: scroll;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	align-items: center;
}

.form--row {
	width: 80%;
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.fragment {
	width: 100%;
	height: 50px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.container--tuple--relation {
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.container--info--tuple {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.required:after {
	content: " *";
	color: red;
}

.group--input {
	display: flex;
	flex-direction: row;
	position: relative;
	width: 45%;
	margin-top: 30px;
}

.rerel {
	width: 100%;
}

/* ------------ INPUT -------------------------- */

.group--input input {
	width: calc(100% - 7px);
	margin-left: 6px;
	margin-top: 5px;
	font-size: 25px;
	padding: 10px 8px 0px 8px;
	font-family: "Betm Rounded Regular";
	border-bottom: 2px solid;
	outline: none;
	border-top: none;
	border-left: none;
	border-right: none;
	background: transparent;
}
.duplicate--property {
	background-color: #fff;
	color: #e7362d;
	font-family: "Betm Rounded Regular";
	border-radius: 15px;
}

.display {
	display: none;
}

.floating--label--input {
	left: 10px;
	margin-top: 15px;
	font-size: 25px;
	position: absolute;
	font-family: "Betm Rounded Regular";
	pointer-events: none;
	transition: 0.2s ease all;
}

.floating--label--input--error {
	left: 10px;
	margin-top: 60px;
	font-size: 15px;
	position: absolute;
	font-family: "Betm Rounded Regular";
	pointer-events: none;
}

.floating--label--input--update {
	left: 10px;
	margin-top: -10px;
	font-size: 20px;
	opacity: 1;
	position: absolute;
	font-family: "Betm Rounded Regular";
	pointer-events: none;
	background: none;
}

.group--input input:focus ~ .floating--label--input,
.group--input input:not(:focus):not([value=""]) ~ .floating--label--input {
	left: 10px;
	margin-top: -10px;
	font-size: 20px;
	opacity: 1;
	background: none;
}

/*---------------------- SELECT ------------------------*/

.group--input select {
	width: calc(100% - 7px);
	margin-left: 6px;
	margin-top: 10px;
	font-size: 25px;
	padding: 10px 8px 2px 2px;
	font-family: "Betm Rounded Regular";
	border-bottom: 2px solid;
	outline: none;
	border-top: none;
	border-left: none;
	border-right: none;
	background: none;
}

.floating--label--select {
	left: 10px;
	margin-top: 10px;
	font-size: 20px;
	position: absolute;
	font-family: "Betm Rounded Regular";
	pointer-events: none;
	transition: 0.2s ease all;
}

.group--input select:focus ~ .floating--label--select,
.group--input select:not(:focus):valid ~ .floating--label--select {
	top: -20px;
	font-size: 13px;
	opacity: 1;
}

.handle--tuple {
	/* width: 4%; */
	margin-top: 10px;
	opacity: 0.5;
}

.handle--tuple:hover {
	opacity: 1;
	cursor: pointer;
}

.container--form--button {
	width: 100%;
	margin-top: 40px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.optionList {
	background-color: transparent;
}
