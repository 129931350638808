.graph {
	width: 100%;
	height: 100%;
    background-color: #f2f2f2;
}

.wrap tspan {
    font-family: 'Betm Rounded Light';
}

.graph__type-wrapper {
    z-index: 99;
    background-color: transparent;
    right: 0;
    top: 100px;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
} 

.graph__empty-text {
    position: fixed;
    top: calc(4em + 50px);
    left: 155px;
    z-index: 9;
}

.graph__empty-create {
    position:fixed;
    top: 0;
    left: calc(150px + 40%);
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 9;
}

.graph__empty-saveCarto {
    position: fixed;
    top: calc(4em + 50px);
    left: 0;
    width: 100px;
    height: 250px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.graph__empty-exportCarto {
    position: fixed;
    top: calc(4em + 200px);
    left: 80px;
    width: 100px;
    height: 200px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.graphBtn:hover {
    cursor: pointer;
}

.graph__type-wrapper--footer {
    position: fixed;
    left: 100px;
    bottom: 0;
}

.graph__btn {
    z-index: 9;
    background: none;
    border: none;
}

.graph__btn--type {
    color: rgb(84, 84, 84);
    margin: 0px 5px;
    padding: 0px 5px;
    font-size: 13px;
}

.graph__btn--type:hover {
    background-color: rgb(84, 84, 84);
    color: white;
    border-radius: 50px;
}

.graph__btn--type--clicked {
    background-color: rgb(84, 84, 84);
    color: white;
    border-radius: 50px;
}

.context-menu {
    font-size: 20px;
    background-color: rgb(240, 240, 240);
    border: 4px solid rgb(109, 109, 109);
    border-radius: 10px;
    padding: 5px;
    width: auto;
    height: auto;
    margin: 0;
  /* use absolute positioning  */
    position: absolute;
    list-style: none;
    box-shadow: 5px 5px 10px 0 rgb(84, 84, 84);
    opacity: 1;
    transition: opacity 0.5s linear;
    z-index: 99;
}

.context__btn {
    margin: 0px 5%;
    text-align: start;
    width: 90%;
}

.context__btn:hover {
    background-color: rgb(220, 220, 220);
    border-radius: 4px;
}
/* 
.context__btn--panel-btn::after {
    content: " >";
}*/

.filtered {
    display: hidden;
}

.context__divider {
    margin: 5px 5%;
    width: 90%;
    text-align: center;
}

.type-config {
    color: grey;
	font-weight: bold;
	font-size: 13px;
}

.type-config__wrapper {
    display: flex;
}

.type-config__wrapper--center {
    align-items: center;
}

.type-config__radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
}

.visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

.type-config__radio:checked+label {
    background-color: rgb(84, 84, 84);
    color: white;
}

.type-config__radio--rounded:checked+label {
    border-radius: 50%;
}


.type-config__label {
    margin: 0px 5px;
    padding: 2px;
    border: 1px solid rgb(84, 84, 84);
    border-radius: 5px;
	font-weight: normal;
}

.type-config__label--no-border {
    border: none;

}

.type-config__radius-circle {
    background-color: rgb(168,168,168);
    border-radius: 50%;
	border: 0px solid rgb(84, 84, 84);
    margin: 2px;
}

.type-config__radius-circle--25 {
    height: 10px;
    width: 10px;
}

.type-config__radius-circle--50 {
    height: 20px;
    width: 20px;
}

.type-config__radius-circle--75 {
    height: 30px;
    width: 30px;
}

rect.selection {
    stroke          : rgb(84, 84, 84);
    stroke-dasharray: 4px;
    stroke-opacity  : 0.5;
    fill            : transparent;
}

circle {
    height: 100%;
    border-radius: 100%;
    text-align: center;
    line-height: 200px;
    font-size: 30px;
}

circle text {
    line-height: normal;
    display:inline-block;
    vertical-align: middle;
}

circle.inner {
	stroke: white;
	stroke-width: 1;
}

g.node circle.outer {
	stroke: black;
	display: none;
	stroke-dasharray: 6px;
	stroke-opacity: 0.5;
    stroke-width: 4;
	fill: transparent;
}

g.link > line.outer {
	stroke: black;
	display: none;
	stroke-dasharray: 4px;
	stroke-opacity: 1;
	fill: transparent;
}

g.node.selected circle.outer {
	display: inline;
}

g.link.selected > line.outer {
	display: inline;
}

div.selectionModal {
	display: none;
}

div.dataModal {
	display: none;
}

div.nodeModal {
	display: none;
}

div.selectionModal.showing {
    z-index: 100;
    position: absolute;
    width: 50%;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-70%, -50%);
    box-shadow: 1px 1px 4px rgb(84, 84, 84);
    border-radius: 8px;
}

div.dataModal {
	display: none;
}

div.dataModal.showing {
    z-index: 100;
    position: absolute;
    width: 50%;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-10%, -50%);
    box-shadow: 1px 1px 4px rgb(84, 84, 84);
    border-radius: 8px;
}

div.nodeModal {
	display: none;
}

div.nodeModal.showing {
    z-index: 100;
    position: absolute;
    width: 50%;
    display: block;
    box-shadow: 1px 1px 4px rgb(84, 84, 84);
    border: 1px solid black;
    border-radius: 8px;
}

.path-btn__slice {
    z-index: 200;
    fill: #939393e3;
    stroke: #6a6a6a;
    animation: pathBtnScaling .2s forwards;
}

.link-text {
	stroke-opacity: 0;
	font-size: 12px;
    font-family: 'Betm Rounded Light';
}

@keyframes pathBtnScaling {
	from {
		transform: scale(0);
		transform-origin: 50% 50%;
		transform-box: fill-box;
	}
	to {
		transform: scale(1);
		transform-origin: 50% 50%;
		transform-box: fill-box;
	}
}
svg *::selection {
	background: transparent;
}

svg *::-moz-selection {
	background: transparent;
}

svg *::-webkit-selection {
	background: transparent;
}

.type-control__wrapper {
    display: flex;
    flex-direction: column;
}

.type-control {
    display: flex;
    flex-direction: column;
}

/* .nodeControle {
	position: absolute;
	bottom: 250px;
} */
