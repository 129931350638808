.container--popupSettings {
	width: 60%;
	height: 80%;
	position: fixed;
	top: 10%;
	left: 20%;
	z-index: 60;
}

.background--popupSettings {
	/* -webkit-backdrop-filter: blur(10px); */
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0px;
	left: 0px;
	/* filter: blur(5px); */
	background-color: rgba(255, 255, 255, 0.623);
	/* ============ */
}

.settings {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 50px;
}

.setting {
    height: 50px;
    width: 50px;
    margin: 5px;
    padding: 5px;
}

.setting--selected {
    height: 50px;
    width: 50px;
    margin: 5px;
    padding: 5px;
    border: 3px solid black;
}

.content {
	width: auto;
}