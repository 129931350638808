.table--page {
	width: 100%;
	/* border-radius: 20px; */
}

/* ======================================= */

.delete--logo {
	width: 50%;
	cursor: pointer;
	opacity: 0.5;
}

.delete--logo:hover {
	opacity: 1;
}

.update--logo {
	width: 50%;
	cursor: pointer;
	opacity: 0.7;
}

.update--logo:hover {
	opacity: 1;
}
