:root {
	--bg--componentColor: #7079b9;
	--bg--relationColor: #8758a0;
}

/* Works on Firefox */
* {
	/* position: fixed; */
	scrollbar-width: 10px;
	scrollbar-color: #7079b9 #e8f2fe;
}

/* relationPageColor {
	position: fixed;
	scrollbar-width: 10px;
	scrollbar-color: #8758a0 #e8f2fe;
} */

/* Works on Chrome, Edge, and Safari */
.componentPageColor::-webkit-scrollbar-thumb {
	background-color: var(--bg--componentColor);
	border-radius: 20px;
}

.relationPageColor::-webkit-scrollbar-thumb {
	background-color: var(--bg--relationColor);
	border-radius: 20px;
}

.componentPageColor::-webkit-scrollbar-corner {
	background: #e8f2fe;
}

.componentPageColor::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

.relationPageColor::-webkit-scrollbar-track {
	background: #e8f2fe;
}

.relationPageColor::-webkit-scrollbar-corner {
	background: #e8f2fe;
}

.relationPageColor::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

.relationPageColor::-webkit-scrollbar-track {
	background: #e8f2fe;
}

.table__add :hover{
	cursor: pointer;
}
